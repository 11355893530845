<template>
  <!-- AQUI -->
  <BaseModal
    name="CampanhasListLeads"
    id="CampanhasListLeads"
    idModal="CampanhasListLeads"
    size="xl"
    :title="filterTitle"
    @shown="fetchLeads"
  >
    <div class="box-total">
      <div class="total-vendas info"></div>
      <div>
        <p class="total-vendas">
          Total de e-mails:
          <span>{{ total }}</span>
        </p>
      </div>
      <div></div>
    </div>
    <div class="header-table">
      <!-- SELECT -->
      <div class="select">
        <multiselect
          v-model="selectedType"
          :options="types"
          label="title"
          class="select-camp"
          track-by="id"
          placeholder="Pesquisar"
          selectLabel="Selecionar"
          deselectLabel=""
          selectedLabel=""
        >
        </multiselect>
      </div>
      <!-- SEARCH -->
      <div class="inputSearch">
        <img src="@/assets/img/icons/search.svg" class="Pointer search" />
        <input
          type="text"
          placeholder="Pesquisar e-mail"
          v-model="search"
          @input="debounce"
          class="input-busca"
        />
        <!-- @input="debounce" -->
      </div>
    </div>
    <!-- HEADER -->
    <div class="Table-header">
      <b-row class="text-center">
        <b-col class="d-none d-md-block" cols="4">E-mail</b-col>
        <b-col class="d-none d-md-block" cols="2">Data de envio</b-col>
        <b-col class="d-none d-md-block" cols="2">Status</b-col>
        <b-col class="d-none d-md-block" cols="2">Data de abertura</b-col>
        <b-col class="d-none d-md-block" cols="2">Data do clique</b-col>
      </b-row>
    </div>
    <!-- BODY -->
    <div class="Table-body" v-for="(lead, index) in leads" :key="index">
      <b-row class="text-center">
        <b-col cols="4">{{ lead.email }}</b-col>
        <b-col cols="2">{{ lead.envio }}</b-col>
        <b-col cols="2">{{ lead.status }}</b-col>
        <b-col cols="2">{{ lead.aberto }}</b-col>
        <b-col cols="2">{{ lead.clique }}</b-col>
      </b-row>
    </div>
    <!-- NENHUM LEAD ENCONTRADO -->
    <b-row
      v-if="!loading && !leads2.length"
      class="justify-content-center style"
    >
      <p class="nao-encontrado">Nenhum lead foi encontrado</p>
    </b-row>
    <!-- PAGINATION -->
    <b-row>
      <b-col>
        <Paginate
          :totalPages="pagination.totalPages"
          :activePage="pagination.currentPage"
          :disabled="true"
        />
        <!-- @to-page="toPage"
          @per-page="perPage" -->
      </b-col>
    </b-row>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="black" :disabled="loading">
        Salvar Alterações
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>

<script>
import Paginate from "@/components/Paginate";
import Multiselect from "vue-multiselect";
//
import LeadListaService from "@/services/resources/LeadListaService";
const serviceListaLeads = LeadListaService.build();
//
import moment from "moment-timezone";
//
import _ from "lodash";
//
export default {
  props: ["cardSelected", "emailSelected"],
  components: { Paginate, Multiselect },
  data() {
    return {
      dateRange: {
        startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        endDate: moment(),
      },
      leads2: [],
      filters: {},
      leads: [
        {
          id: 1,
          email: "teste@teste.com.br",
          envio: "2021-06-23 18:06:04",
          status: "Clicou",
          aberto: "2021-06-23",
          clique: "0000-00-00",
        },
        {
          id: 1,
          email: "greenn@greenn.com.br",
          envio: "2022-08-03 17:54:39",
          status: "Aberto",
          aberto: "2022-09-03",
          clique: "0000-00-00",
        },
      ],
      types: [
        { id: 1, title: "Todos" },
        { id: 2, title: "Abertos" },
        { id: 3, title: "Clicados" },
      ],
      selectedType: { id: 1, title: "Todos" },
      search: null,
      loading: false,
      total: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
    };
  },
  watch: {
    selectedType() {
      // console.log("buscar dados 2222", this.leads2);
    },
  },
  computed: {
    toPage(page) {
      this.pagination.currentPage = page;
      // this.fetchCampanhas(this.search);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      // this.fetchCampanhas(this.search);
    },
    filterTitle() {
      var name = "[Titulo do email]";
      if (this.emailSelected && this.emailSelected.title) {
        return `Lista de Leads do e-mail - ${this.emailSelected.title}`;
      } else {
        return `Lista de Leads do e-mail - ${name}`;
      }
    },
  },
  methods: {
    debounce: _.debounce(function () {
      this.fetchLeads(this.search);
    }, 500),
    configFilter(search) {
      if (search) {
        this.pagination.currentPage = 1;
      }
      let data = {
        filters: {
          importacoes: [],
          status: this.status === true ? "active" : "inactive",
          search: "",
          campanha: [],
          tag: [],
          tag_exceto: [],
          email: [],
          status_email: "",
          formulario: [],
          vendedor: [],
          funil: [],
          leadscore_minimo: "",
          leadscore_maximo: "",
          campos_adicionais: [],
          data_inicio: "",
          data_fim: "",
          acao_email_tempo: "",
          higiene: "",
          campo_data: this.type_date,
          data_inicial: null,
          data_final: null,
          board: [],
          pipeline: [],
          email_click: [],
          email_open: [20],
          email_not_click: [],
          email_not_open: [],
          email_spams: [],
          email_unsubscribeds: [],
          email_bounces: [],
          email_droppeds: [],
          ids: this.import_leads,
          campaign_id: [parseInt(this.$route.params.id)],
        },
        leads: [],
        withs: ["Tags", "Metas", "Views"],
        order: "DESC",
        orderby: this.type_date,
        page: this.pagination.currentPage,
        pagina: this.pagination.currentPage,
        total_pagina: this.pagination.perPage,
      };

      if (search != null && typeof search == "string" && search.length > 0) {
        data.filters.search = search;
      }

      if (this.dateRange.startDate != null && this.dateRange.endDate != null) {
        data.filters.data_inicial = moment
          .tz(this.dateRange.startDate, "America/Sao_Paulo")
          .subtract(1, "month")
          .format("YYYY-MM-DD");
        data.filters.data_final = moment
          .tz(this.dateRange.endDate, "America/Sao_Paulo")
          .format("YYYY-MM-DD");
      }

      Object.keys(this.filters).forEach(
        (key) =>
          (this.filters[key] == null || this.filters[key].length === 0) &&
          delete this.filters[key]
      );
      data.filters = Object.assign(data.filters, this.filters);

      return data;
    },
    fetchLeads(search = null) {
      this.loading = true;
      this.pagination.totalPages = 1;

      var data = this.configFilter(search);
      this.filters = data.filters;
      data.filters.status = this.status === true ? "active" : "inactive";
      data.filters.data_inicial = moment
        .tz(this.dateRange.startDate, "America/Sao_Paulo")
        .format("YYYY-MM-DD");
      data.filters.data_final = moment
        .tz(this.dateRange.endDate, "America/Sao_Paulo")
        .format("YYYY-MM-DD");
      data.filters.search = this.search;
      serviceListaLeads
        .create(data)
        .then((response) => {
          this.total = response.data.length;
          this.leads2 = response.data;
          this.pagination.totalPages = response.last_page;
          this.total = response.total;
          // if (this.all_leads) {
          //   this.clickCheckAll(true);
          // }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  // mounted() {
  //   this.fetchLeads();
  // },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss2/main.scss";

.nao-encontrado {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  border-radius: 10px;
  padding: 20px !important;
  background: #f7f7f7;
  margin: 20px auto;
}
.style {
  background: #fafafb;
  min-height: 75px;
  position: relative;
  align-items: center;
  padding: 15px 30px;
  border-radius: 0;
}

.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}
.inputSearch {
  position: relative;
  input {
    width: 244px;
    margin: 0 !important;
  }
}
.input-busca {
  width: 250px;
  height: 50px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
  border: 1px solid #ededf0;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  top: 24px;
  left: 15px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
.total-vendas {
  display: flex;
  align-items: center;
  margin: 0px;
  font-weight: 600;
  font-size: 16px;
  color: var(--gray01);
  span {
    font-size: 20px;
    font-weight: 600;
    color: var(--greenn);
    margin-left: 5px;
  }
}
.total-vendas.info {
  font-size: 14px;
  font-weight: normal;
  color: var(--gray01);
}
.box-total {
  background: var(--greenn2);
  padding: 30px !important;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--greenn);
}
.Table-body {
  min-height: 75px;
  position: relative;
  padding: 15px 30px 0 30px;
  background: #fff;
  border-radius: 0;
  div {
    top: 10px;
  }
  &::before {
    content: "";
    width: 6px;
    height: 6px;
    background: var(--greenn);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: 10px;
    top: 33px;
    transition: all 0.3s ease;
  }
}
.Table-header {
  padding: 20px;
  border-radius: 0;
  border-bottom: 1px #ededf0 solid;
  div {
    font-size: 14px;
    color: var(--gray01);
    font-weight: 600;
  }
}
</style>
